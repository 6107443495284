<template>
  <div>
    <b-overlay
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row class="match-height">
          <transition
          mode="out-in"
          >
          <b-col md="6">
            <b-card>
                <b-form @submit.prevent="update">
                <b-row>
                  <!-- price -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Price')"
                      label-for="v-price"
                    >
                      <b-form-input
                        id="v-title"
                        :placeholder="$t('Price')"
                        v-model="daily.price"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- quantity -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Quantity')"
                      label-for="v-quantity"
                    >
                        <b-form-input
                          id="v-quantity"
                          :placeholder="$t('Quantity')"
                          v-model="daily.quantity"
                        />
                    </b-form-group>
                  </b-col>
                  <!-- start_date -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Start Date')"
                      label-for="v-start_date"
                    >
                        <b-form-datepicker
                          id="v-start_date"
                          :placeholder="$t('Start Date')"
                          v-model="daily.start_date"
                        />
                    </b-form-group>
                  </b-col>
                  <!-- end_date -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('End Date')"
                      label-for="v-end_date"
                    >
                        <b-form-datepicker
                          id="v-end_date"
                          :placeholder="$t('End Date')"
                          v-model="daily.end_date"
                        />
                    </b-form-group>
                  </b-col>
                  <!-- product_id -->
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Product')"
                      label-for="v-product_id"
                    >
                        <v-select
                          v-model="daily.product_id"
                          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                          label="name"
                          :options="products"
                        />
                    </b-form-group>
                  </b-col>
                  <!-- submit -->
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      <template v-if="!isLoadingSave">
                        {{$t('Save')}}
                      </template>
                      <template v-if="isLoadingSave">
                        {{$t('Saveing')}}...
                        <b-spinner small />
                      </template>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-col>
          </transition>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  name: 'PagesUpdate',

  data() {
    return {
      isLoading: false,
      isLoadingSave: false,
      products: [],
      daily: {}
    }
  },
  mounted() {
      this.getDaily();
      this.getProducts();
  },
  methods: {
    getDaily(){
        this.isLoading = true
        useJwt.post('/daily-deals/get-by-id',{id: this.$route.params.id})
        .then((response) => {
          this.daily = response.data.data
          if(this.daily.product_id != null) {
              this.daily.product_id = this.daily.product.name
          }
          this.isLoading = false
        })
        .catch(response => {
          // console.log(response);
        });
    },
    getProducts(){
      useJwt.get('/products/get-all')
      .then((response) => {
         console.log(response.data)
        this.products = response.data.data
      })
      .catch(response => {
        // console.log(response);
      });
    },
    update() {
      this.isLoadingSave = true;
      useJwt.post('/daily-deals/update',this.daily)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.$root.showToast('success', 'save', '')
      })
      .catch(error => {
        this.isLoadingSave = false
        let error_msg = ''
        if(error.response.data.message.price != undefined) {
            error_msg+=error.response.data.message.price
        }
        if(error.response.data.message.start_date != undefined) {
            error_msg+=error.response.data.message.start_date
        }
        if(error.response.data.message.end_date != undefined) {
            error_msg+=error.response.data.message.end_date
        }
        if(error.response.data.message.product_id != undefined) {
            error_msg+=error.response.data.message.product_id
        }
        this.$root.showAlert('error', error_msg)
      });
    }
  }
}
</script>
